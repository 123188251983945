:root {
    --nutui-tabbar-border-bottom: none;
    --nutui-tabbar-border-top: none;
    --nutui-tabbar-text-font-size: 12px;

    --nutui-tabs-titles-height: 72px;
    --nutui-tabs-titles-background-color: none;

    --nutui-tabs-tabpane-backgroundColor: none;
    --nutui-tabs-tabpane-padding: none;

    --nutui-inputnumber-button-width: 22px;
    --nutui-inputnumber-button-height: 22px;
    --nutui-inputnumber-input-background-color: transparent;
    --nutui-inputnumber-input-width: 24px;

    --nutui-textarea-padding: 5px 10px;

    --nutui-tabs-titles-font-size: 16px;

    --nutui-input-padding: 5px 10px;

    --nutui-noticebar-background: rgba(250, 44, 25, 0.5);
    --nutui-noticebar-color: #ffffff;
    --nutui-noticebar-font-size: 16px;

    --nutui-popup-title-padding: 25px;

    --nutui-list-item-margin: 0 0 5px 0;
}

::-webkit-scrollbar {
    display: none; /* 隐藏滚动条 */
}

.margin_bottom_6 {
    margin-bottom: 6px;
}

.f20 {
    font-size: 20px;
}

.f18 {
    font-size: 18px;
}

.f16 {
    font-size: 16px;
}

.f14 {
    font-size: 14px;
}

.f12 {
    font-size: 12px;
}

.f10 {
    font-size: 10px;
}

body {
    margin: 0;
    font-family: "Helvetica";
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

.flex_center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.banner_badge {
    color: white;
    position: absolute;
    height: 30px;
    background-color: var(--nutui-color-primary);
    right: -10px;
    top: 10px;
    text-align: center;
    line-height: 30px;
    padding: 0 10px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
}

.banner_triangle {
    position: absolute;
    width: 10px;
    height: 8px;
    background-color: var(--nutui-brand-7);
    right: -10px;
    top: 40px;
    clip-path: polygon(100% 0, 0 100%, 0 0);
}

.tab_container {
    font-size: 12px;
    height: 72px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 10px;
}

.tab_icon_container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0);
    background-color: white;
}

.tab_icon_container_active {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: var(--nutui-color-primary);
    background-color: white;
}

.tab_text_active {
    color: var(--nutui-color-primary);
    white-space: nowrap;
}

.tab_text {
    white-space: nowrap;
}

.nut-tabs-titles-line {
    justify-content: center;
    margin-bottom: 10px !important;
}

.tab_pane_goods_container {
    height: 68px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 5px;
    padding: 5px;
}

.tab_pane_goods_text_container {
    margin-left: 10px;
    flex: auto;
}

.tab_pane_goods_text_sub_container {
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.nut-input-minus {
    border: 1px solid var(--nutui-color-primary);
}

.nut-input-add {
    border: 1px solid var(--nutui-color-primary);
}

.goods_detail_price_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no_data {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 20px;
    font-size: 20px;
}

.nut-swipe {
    background-color: transparent !important;
}

.nut-textarea {
    border-radius: var(--nutui-radius-4);
}

.dotted_line {
    flex: 1;
    border-top: 1px dashed var(--nutui-gray-7);
    margin: 0 20px;
}

.order_item_container {
    background-color: white;
    border-radius: 15px;
    padding: 15px 15px;
    margin-bottom: 5px;
}

.red {
    background-color: var(--nutui-color-primary);
}

.avatar_container {
    background-color: white;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 10px;
    margin-top: 20px;
}

.avatar_text_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 10px;
    height: 50px;
}

.person_title_container {
    height: 17px;
    margin: 14px 0 10px 0;
}

.person_popup_container {
    padding: 0 20px 20px 20px;
}

.person_popup_inner_container {
    padding: 0 20px;
}

.person_popup_input_container {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--nutui-gray-7);
    margin-bottom: 15px;
}

.person_forget_text_container {
    height: 35px;
    margin-bottom: 15px;
    line-height: 35px;
}

.person_forget_title_container {
    display: flex;
    align-items: center;
    margin: 26px 0;
}

.person_information_container {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
}

.person_information_item {
    display: flex;
}

.person_information_text {
    font-size: 14px;
    margin-left: 5px;
    line-height: 23px;
}

.person_edit_popup_container {
    padding: 20px 40px;
}

.person_edit_popup_address_container {
    width: calc(100vw - 122px);
    height: 194px;
    position: absolute;
    z-index: 999;
    top: 40px;
    border-radius: 10px;
    border: 1px solid var(--nutui-gray-3);
    padding: 5px 20px;
    background-color: white;
    overflow-y: scroll;
}

.address_item_container {
    border-bottom: 1px solid var(--nutui-gray-3);
    width: 100%;
    white-space: normal;
    overflow-wrap: break-word;
    margin-bottom: 12px;
    padding-bottom: 4px;
}

.cart_hint_container {
    color: var(--nutui-color-primary);
    height: 47px;
    background-color: white;
    border-radius: 6px;
    margin-bottom: 2px;
    display: flex;
    font-size: 14px;
    justify-content: center;
    align-items: center;
}

.nut-noticebar {
    position: absolute;
}

.icon-euro-outline {
    position: relative;
    top: -1px;
}

.icon-euro-fill {
    position: relative;
    top: -1px;
}

.icon-clock-outline {
    position: relative;
    top: -1px;
}

.icon-clock-fill {
    position: relative;
    top: -1px;
}

.icon-bag-outline {
    position: relative;
    top: -1px;
}

.icon-bag-fill {
    position: relative;
    top: -1px;
}

.time_select_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 5px;
}

.time_input {
    padding: 0 5px !important;
    width: 70px !important;
    margin-right: 5px;
    margin-left: 5px;
    border-radius: 5px !important;
}

.overlength_text {
    width: calc(100vw - 60px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 5px;
}

.disable_mask {
    background-color: rgba(50, 50, 50, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
}

ul.nut-virtuallist-items.nut-vertical-items {
    padding: 0;
    margin: 0;
}

.nut-vertical-items li {
    border-radius: 15px !important;
    margin-top: 0 !important;
    padding: 15px !important;
}
