@font-face {
  font-family: "iconfont"; /* Project id 4634942 */
  src: url('iconfont.woff2?t=1727600886746') format('woff2');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clock-outline:before {
  content: "\e64d";
}

.icon-truck-outline:before {
  content: "\e64e";
}

.icon-bag-outline:before {
  content: "\e64f";
}

.icon-home-outline:before {
  content: "\e64c";
}

.icon-receipt-fill:before {
  content: "\e64a";
}

.icon-receipt-outline:before {
  content: "\e64b";
}

.icon-edit-outline:before {
  content: "\e647";
}

.icon-phone-outline:before {
  content: "\e648";
}

.icon-edit-fill:before {
  content: "\e649";
}

.icon-key-fill:before {
  content: "\e645";
}

.icon-key-outline:before {
  content: "\e646";
}

.icon-setting-fill:before {
  content: "\e643";
}

.icon-setting-outline:before {
  content: "\e644";
}

.icon-user-outline:before {
  content: "\e62e";
}

.icon-trash-outline:before {
  content: "\e630";
}

.icon-user-fill:before {
  content: "\e632";
}

.icon-trash-fill:before {
  content: "\e634";
}

.icon-squares-fill:before {
  content: "\e635";
}

.icon-squares-outline:before {
  content: "\e636";
}

.icon-shopping-cart-fill:before {
  content: "\e637";
}

.icon-shopping-cart-outline:before {
  content: "\e638";
}

.icon-location-fill:before {
  content: "\e639";
}

.icon-location-outline:before {
  content: "\e63a";
}

.icon-credit-outline:before {
  content: "\e63b";
}

.icon-credit-fill:before {
  content: "\e63c";
}

.icon-euro-fill:before {
  content: "\e63d";
}

.icon-euro-outline:before {
  content: "\e63e";
}

.icon-email-outline:before {
  content: "\e63f";
}

.icon-email-fill:before {
  content: "\e640";
}

.icon-lock-fill:before {
  content: "\e641";
}

.icon-lock-outline:before {
  content: "\e642";
}

